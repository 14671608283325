import { call, put, takeLatest } from 'redux-saga/effects';
import http from '../Network/http';
import {COMPLIANCE, getBaseUrl} from '../Network/baseUrl';
import {
  GET_EKYC_DOCUMENT_STATUS,
  GET_EKYC_DOCUMENT_STATUS_FAILURE,
  GET_EKYC_DOCUMENT_STATUS_SUCCESS,
  USER_ATTACHMENT_UPLOAD,
  USER_ATTACHMENT_UPLOAD_SUCCESS,
  USER_ATTACHMENT_UPLOAD_FAILURE
} from "../Assets/Constant/EkycDocumentsStatus";

function* getEkycDocumentsStatus() {
  const region = localStorage.getItem('region');

  try {
    const apiConfig = {
      method: 'get',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/ekyc/documents/upload',
      params: {
        region
      }
    };

    const res = yield call(http, apiConfig);

    const { data } = res;

    if (res.status === 200) {
      yield put({
        type: GET_EKYC_DOCUMENT_STATUS_SUCCESS,
        data,
      });
    } else {
      yield put({
        type: GET_EKYC_DOCUMENT_STATUS_FAILURE,
        error: data.error,
      });
    }
  } catch (error) {
    yield put({
      type: GET_EKYC_DOCUMENT_STATUS_FAILURE,
      error,
    });
  }
}

function* uploadUserAttachment(items) {
  const region = localStorage.getItem('region');

  const { data } = items;

  const apiConfig = {
    method: 'post',
    baseURL: getBaseUrl(COMPLIANCE),
    url: '/ekyc/documents/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
    },
    params:{
      region
    }
  };

  let didSuccessfullyUploadEverything = true;

  for (const image of data) {
      const uploadResponse = yield call(postUploadUserAttachment, apiConfig, image);
      if (!uploadResponse.isSuccessful) {
        yield put({
          type: USER_ATTACHMENT_UPLOAD_FAILURE,
          error: uploadResponse.data,
        });

        didSuccessfullyUploadEverything = false;

        break;
    }
  }

  if (didSuccessfullyUploadEverything) {
    yield put({
      type: USER_ATTACHMENT_UPLOAD_SUCCESS,
      data: {
        isSuccessful: true,
      },
    });
  }
}

function* postUploadUserAttachment(apiConfig, image) {
  try {
    const formData = new FormData();
    formData.append('document',image.file)
    formData.append('type', image.type)
    formData.append('expiry_date', image.date)

    apiConfig.data = formData;
    const res = yield call(http,apiConfig);
    const data = yield res.data;
    if (res.status === 200) {
      return {
        isSuccessful: true,
        data,
      };
    }

    return {
      isSuccessful: false,
      data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      data: error,
    };
  }
}


export function* watchEkycDocumentsStatus() {
  yield takeLatest(GET_EKYC_DOCUMENT_STATUS, getEkycDocumentsStatus);
  yield takeLatest(USER_ATTACHMENT_UPLOAD, uploadUserAttachment);

}
