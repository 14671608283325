import {
  GET_EKYC_DOCUMENT_STATUS,
  GET_EKYC_DOCUMENT_STATUS_FAILURE,
  GET_EKYC_DOCUMENT_STATUS_RESET,
  GET_EKYC_DOCUMENT_STATUS_SUCCESS,
  SET_EKYC_DOCUMENT_NEW_IDENTIFICATION,
  SET_EKYC_DOCUMENT_NEW_VISA,
  USER_ATTACHMENT_UPLOAD, USER_ATTACHMENT_UPLOAD_FAILURE, USER_ATTACHMENT_UPLOAD_RESET, USER_ATTACHMENT_UPLOAD_SUCCESS
} from "../Assets/Constant/EkycDocumentsStatus";

const initialState = {
  isLoading: false,
  data: [],
  requiredDocuments: [],
  isUploading: false,
  response: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EKYC_DOCUMENT_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_EKYC_DOCUMENT_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    case GET_EKYC_DOCUMENT_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.data,
        data: null,
      };
    case GET_EKYC_DOCUMENT_STATUS_RESET:
      return {
        isLoading: false,
        data: [],
        error: null,
      };
    case USER_ATTACHMENT_UPLOAD:
      return {
        ...state,
        isUploading: true,
      };
    case USER_ATTACHMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        isUploading: false,
        response: action.data,
        error: null,
        requiredDocuments: [],
      };
    case USER_ATTACHMENT_UPLOAD_FAILURE:
      return {
        ...state,
        isUploading: false,
        response: null,
        error: action.error,
        requiredDocuments: [],
      };
    case USER_ATTACHMENT_UPLOAD_RESET:
      return {
        ...state,
        requiredDocuments: [],
        isUploading: false,
        response: null,
        error: null,
      };
    default:
      return state;
  }
}
