import {
  GET_BENEFICIARY_FORM,
  GET_BENEFICIARY_FORM_SUCCESS,
  GET_BENEFICIARY_FORM_FAIL,
  REMOVE_BENEFICIARY_FORM_ERROR,
  VERIFY_BENEFICIARY_FORM,
  VERIFY_BENEFICIARY_FORM_SUCCESS,
  VERIFY_BENEFICIARY_FORM_FAIL,
  SUBMIT_BENEFICIARY_FORM,
  SUBMIT_BENEFICIARY_FORM_SUCCESS,
  SUBMIT_BENEFICIARY_FORM_FAIL,
  SUBMIT_EDIT_BENEFICIARY_FORM,
  SUBMIT_EDIT_BENEFICIARY_FORM_SUCCESS,
  SUBMIT_EDIT_BENEFICIARY_FORM_FAIL,
  SUBMIT_BENEFICIARY_BANK_FORM,
  UPDATE_BENEFICIARY_FILLED_DATA,
  RESET_BENEFICIARY_FILLED_DATA,
  RESET_BENEFICIARY_FORM, SUBMIT_BENEFICIARY_BANK_FORM_FAIL,
} from '../Assets/Constant/BeneficiaryForm';
import {REMOVE_FLEX_FORM_ERROR} from "../Assets/Constant/CompleteProfileForm";

const initialState = {
  loading: false,
  data: [],
  additionalFieldData: [],
  additionalFieldDataVisible: [],
  senderEntity: [],
  bankForm: [],
  filledData: {
  },
  entityType: [],
  field_group: [],
  error: null,
  bankFormError: null,
};

const beneficiaryFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BENEFICIARY_FILLED_DATA:
      return {
        ...state,
        filledData: {
          ...state.filledData,
          ...action.data,
        },
      };
    case RESET_BENEFICIARY_FILLED_DATA:
      return {
        ...state,
        filledData: {
        },
      };
    case RESET_BENEFICIARY_FORM:
      return {
        loading: false,
        data: [],
        additionalFieldData: [],
        additionalFieldDataVisible: [],
        senderEntity: [],
        bankForm: [],
        filledData: {

        },
        entityType: [],
        field_group: [],
        error: null,
        bankFormError: null,
      };
    case GET_BENEFICIARY_FORM:
      return {
        ...state,
        loading: true,
      };
    case GET_BENEFICIARY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        entityType: action.data.fields.find((item) => item.name === 'entity_type').options.map(((data) => data.value)),
        field_group: [...new Set(action.data.fields.map((data) => data.field_group))],
        data: action.data.fields.filter((item) => item.field_group !== 'Additional Fields'),
        additionalFieldData: action.data.fields.filter((item) => item.field_group === 'Additional Fields'),
        senderEntity: action.data.fields.find((item) => item.name === 'sender_entity_id'),
      };
    case GET_BENEFICIARY_FORM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case REMOVE_BENEFICIARY_FORM_ERROR:
    case REMOVE_FLEX_FORM_ERROR:
      state.data = state.data.map((item) => {
        if (item.name === action.data) {
          item.error = null;
          item.messages = [];
        }
        return item;
      })
      return state;
    case VERIFY_BENEFICIARY_FORM:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_BENEFICIARY_FORM_SUCCESS:
      return {
        ...state,
        data: state.data.map((obj) => action.data.changes.find((o) => o.name === obj.name) || obj),
        additionalFieldData: state.additionalFieldData.map((obj) => action.data.changes.find((o) => o.name === obj.name) || obj),
        loading: false,
      };
    case VERIFY_BENEFICIARY_FORM_FAIL:
      const newData = state.data.map((obj) => action.data.changes.find((o) => o.name === obj.name) || obj);
      const newAdditionalFieldData = state.additionalFieldData.map((obj) => action.data.changes.find((o) => o.name === obj.name) || obj);
      const newAdditionalFieldDataWithErrors = newAdditionalFieldData.map((obj) => {
        const result = action.errors.filter((id) => obj.name === id.name);
        if (result.length > 0) {
          return {
            ...obj,
            messages: result[0].messages,
            error: true,
          };
        }
        return obj;
      });
      const newDataWithErrors = newData.map((obj) => {
        const result = action.errors.filter((id) => obj.name === id.name);
        if (result.length > 0) {
          return {
            ...obj,
            messages: result[0].messages,
            error: true,
          };
        }
        return obj;
      });
      return {
        ...state,
        data: newDataWithErrors,
        additionalFieldData: newAdditionalFieldDataWithErrors,
        loading: false,
      };



    case SUBMIT_BENEFICIARY_FORM:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_BENEFICIARY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SUBMIT_BENEFICIARY_FORM_FAIL:
      return {
        ...state,
        data: state.data.map((obj) => {
          const result = action.errors.filter((id) => obj.name === id.name);
          if (result.length > 0) {
            return {
              ...obj,
              messages: result[0].messages,
              error: true,
            };
          }
          return obj;
        }),
        additionalFieldData: state.additionalFieldData.map((obj) => {
          const result = action.errors.filter((id) => obj.name === id.name);
          if (result.length > 0) {
            return {
              ...obj,
              messages: result[0].messages,
              error: true,
            };
          }
          return obj;
        }),
        loading: false,
      };
    case SUBMIT_BENEFICIARY_BANK_FORM:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_BENEFICIARY_BANK_FORM_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SUBMIT_EDIT_BENEFICIARY_FORM:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_EDIT_BENEFICIARY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SUBMIT_EDIT_BENEFICIARY_FORM_FAIL:
      return {
        ...state,
        data: state.data.map((obj) => {
          const result = action.errors.filter((id) => obj.name === id.name);
          if (result.length > 0) {
            return {
              ...obj,
              messages: result[0].messages,
              error: true,
            };
          }
          return obj;
        }),
        additionalFieldData: state.additionalFieldData.map((obj) => {
          const result = action.errors.filter((id) => obj.name === id.name);
          if (result.length > 0) {
            return {
              ...obj,
              messages: result[0].messages,
              error: true,
            };
          }
          return obj;
        }),
        loading: false,
      };
    default:
      return state;
  }
};

export default beneficiaryFormReducer;
